/* eslint-disable no-console */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import { StyledModal } from "../LogWorkoutCategoryV2/styles";
import PropTypes from "prop-types";
import {DescriptionContainer} from './SingleEventV2/styles';
import {Description} from "./styles";
import { Interweave } from 'interweave';
import { withTranslation } from 'react-i18next';
class AboutTheEventModal extends Component {

  render() {
    const { onHide, showModal, title, body, t } = this.props;
    return (
      <StyledModal show={showModal} change={0} onHide={() => onHide()} transform={0} >
        <div className={'modal-container'} style={{height:"56px"}}>
          <div className={'left-modal'}>
            <div className={'text'}>
              {t("About Event")}
            </div>
          </div>
          <div onClick={(event) => onHide(event)}>
            <img src="/public/images/NewDashboardV2/new_close_icon.png" width="22px" height="22px" alt="" style={{ "cursor": "pointer" }} />
          </div>
        </div>
        <Modal.Body>
          <DescriptionContainer style={{paddingBottom:'10px'}}>
            <span>
              {t(title)}
            </span>
            <Description padding={1} textAlign={1}>
              <Interweave content={t(body)} />
            </Description>
          </DescriptionContainer>
        </Modal.Body>
      </StyledModal>
    );
  }
}

AboutTheEventModal.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  history: PropTypes.object,
  body: PropTypes.object,
  t: PropTypes.func
};

export default (withTranslation()(AboutTheEventModal));
