import styled from "styled-components";

const Layout = styled.div`
  width: 100%;
  border-radius:6px;
  padding: ${({ padding }) => (padding ? padding : "0 0 25px 0")};
  margin: ${({ margin }) => (margin ? margin : "0 0 25px 0")};
  .cardWrapper{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    padding-left:0px;
    margin-top:25px;
    .cardDiv{
      width:399px;
    }
  }
  .arrows{
    display:flex;
    width:auto 0 auto auto;
    .iconBackground{
      width:32px;
      height:32px;
      border-radius:3px;
      background: rgba(0, 122, 177, 0.05);
      cursor:pointer;
      display:flex;
      justify-content:center;
      align-items:center;
    }
    .text{
      color:#007AB1;
      text-align: center;
      font-family: Rubik;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: auto 20px auto 20px;
      display:flex;
    }
  }
.headingMain{
  width:100%;
  display:flex;
  justify-content:space-between;
  padding: 0px 0px 0px 0px;
  .heading{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color:#005c87;
    align-items: center;
    display: flex;
  }
  .seeAll{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #005c87;
    text-decoration: underline;
    cursor:pointer;
  }
}
.card{
  width: ${({ width }) => (width ? "399px !important" : "100%")};
}
`;

const Container = styled.div`
  width: 100%;
  display:flex;
  flex-wrap:wrap;
  // padding: 0px 15px;
  .card{
    max-width: 1246px;
    border:none;
  }
  .cardiv{
    max-width: 1246px;
    margin:auto;
    margin-top:25px;
  }
`;

const CompanyCard = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  display:flex;
  border-radius:6px;
  cursor:pointer;
  box-shadow: none;
  padding: 16px;
  flex-wrap:wrap;
  .date{
    width:100%;
    display:flex;
    margin-bottom:20px;
    align-items: center;
    .icon{
      width:24px;
      height:24px;
      >img{
        width:100%;
        height:100%;
      }
    }
    .detail{
      color: #005C87;
      font-family: Rubik-Medium; 
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #005c87;
      margin-left:20px;
      display:flex;
      align-items:center
    }
  }
  .eventLabel{
    height:0px;
    width:100%;
    position: relative;
    /* left: 10px;
    top: 10px; */

    .label{
    height:24px;
    width:100px;
    display:flex;
    background:white;
    border-radius:3px;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color:#005c87;
    position:relative;
    align-items: center;
    justify-content: center;
    top:10px;
    left:10px;
    }
  }
  .imageCard{
    width:41.2%;
    height:312px;
    cursor:pointer;
    border-radius: 6px;
    >img{
      width:100%;
      height:100%;
      border-radius: 6px;
    }
  }
  .detailsCard{
    background:white;
    width:58.8%;
    ${'' /* padding:20px 0 20px 20px; */}
    padding: ${({ detailsCardPadding }) => detailsCardPadding || '20px 0 20px 20px'}
    display:flex;
    flex-wrap:wrap;
    flex-direction: column;
    border-radius: 0 6px 6px 0;
    .title{
      font-family: 'Rubik-Medium';
      font-size: 24px;
      line-height: 24px;
      color:#005c87;
      width:100%;
      align-items: center;
      display: flex;
    }
    .details{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color:#005C87;
        width:100%;
        display: flex;
        align-items: center;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // height: 90px;
        padding-top: 7px;
        text-align: justify;
        margin: 12px 0px 20px 0;
    }
    .time{
        font-family: 'Rubik-Medium';
        font-size: 16px;
        line-height: 20px;
        color:#005c87;
        width:100%;
        display: flex;
        align-items: center;
    }
    .participant{
        width:100%;
        display:flex;
        margin-bottom:22px;
        width:100%;
        .count{
          background: #e6eff3;
          border: 2px solid #FFFFFF;
          border-radius: 15px;
          padding:0 7px;
          align-items: center;
          display: flex;
          height:fit-content;
          margin: auto 0px;
          .value{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color:#005c87;
          }
        }
        .participantJoined{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color:#669db7;
            padding-left:10px;
            align-items:center;
            display:flex;
        }
    }
    .buttonContainer{
        width:100%;
        display:flex;
        margin: auto;
        margin-bottom: 0px;
        justify-content:space-between;
    }
  }
`;

const Button = styled.div`
  width:48%;
  width: ${({ width }) => (width ? width : "48%")};
  background: ${({ background }) => (background ? background : "#005c87")};
  box-shadow: ${({ shadow }) => (shadow ? shadow : "none")};
  border: ${({ border }) => (border ? border : "none")};
  border-radius: 3px;
  font-family: 'Rubik-Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ color }) => (color ? color : "white")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  text-decoration-line: ${({ decoration }) => (decoration ? 'underline' : "0")};
  height:50px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor:pointer;
  cursor: ${({ cursor }) => (cursor ? cursor : "pointer")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  div{
    >img{
    width:24px;
    height:24px;
    margin-right:10px;
  }

  }

  >img{
    width:24px;
    height:24px;
    margin-right:10px;
  }
`;

const WolibaCard = styled.div`
  display:block;
  background: white;
  border-radius:6px;
  width: ${({ width }) => (width ? width : "100%")};
  margin: ${({ margin }) => (margin ? margin : "25px 0 15px -50px")};
  // box-shadow: 0px 8px 25px 0px #0D427040;
  max-height:405px;
  .eventLabel{
    height:0px;
    width:100%;
    position: relative;
    /* left: 10px;
    top: 10px; */

    .label{
    min-height:24px;
    width:100px;
    display:flex;
    background:white;
    border-radius:3px;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color:#0D4270;
    position:relative;
    align-items: center;
    justify-content: center;
    top:10px;
    left:10px;
    }
  }
  .imageCard{
    width:100%;
    height:250px;
    border-radius:6px 6px 0 0;
    cursor:pointer;
    >img{
      width:100%;
      height:100%;
      border-radius: 6px 6px 0 0;
    }
  }
  .detailsCard{
    width:100%;
    padding: 15px;
  .titleCard{
    display:flex;
    margin-bottom:5px;
    justify-content: space-between;
    .title{
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color:#005c87;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width:80%;
    }
    .icon{
      height:24px;
      width:24px;
      >img{
        width:100%;
        height:100%;
        cursor:pointer;
      }
    }
  }
  .time{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color:#005c87;
    margin-bottom:16px;
  }

}
`;

const WolibaButton = styled.div`
width:100%;
height:50px;
cursor:pointer;
border-radius:6px;
background: ${({ background }) => (background ? background : "#005c87")};
border: ${({ border }) => (border ? border : "none")};
box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : "none")};
display:flex;
.button-title{
  font-family: 'Rubik-Medium';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* color:white; */
  color: ${({ color }) => (color ? color : "white")};
  align-items: center;
  display: flex;
  text-align:center;
  margin: auto;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.title{
font-family: 'Rubik-Medium';
font-weight: 500;
font-size: 16px;
line-height: 20px;
/* color:white; */
color: ${({ color }) => (color ? color : "white")};
padding-left: 15px;
align-items: center;
display: flex;
div{
  >img{
  width:24px;
  height:24px;
  margin-right:15px;
}

}
>img{
  width:24px;
  height:24px;
  margin-right:15px;
}
}
.participants{
  display:flex;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : "0")};
  .image{
    width:30px;
    height:30px;
    background: #fff;
    border: 2px solid #FFFFFF;
    border-radius:50%;
    margin-right: -12px;
    >img{
      width:100%;
      height:100%;
      border-radius: 50%;
    }
  }
  .count{
    width:30px;
    height:30px;
    background: #fff;
    border: 1px solid #FFFFFF;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #0D4270;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items:center;
  }
}
`;

const MainContainer = styled.div`
width: 100%;
padding: 0px 25px;
.main{
  width: 100%;
  max-width: 1246px;
  margin: auto;
}
`;

const AddToCalendarButton = styled.div`
  width: ${({ width }) => (width ? width : "48%")};
  background: ${({ background }) => (background ? background : "linear-gradient(227.68deg, #00F0FF 15.32%, #3586FF 85.04%)")};
  box-shadow: ${({ shadow }) => (shadow ? shadow : "0px 4px 11px rgba(105, 194, 255, 0.25)")};
  border-radius: 3px;
  font-family: 'Rubik-Medium';
  font-size: 16px;
  line-height: 20px;
  color: ${({ color }) => (color ? color : "white")};
  border: ${({ border }) => (border ? border : "none")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  text-decoration-line: ${({ decoration }) => (decoration ? 'underline' : "0")};
  height:50px;
  display: flex;
  justify-content: ${({ justify }) => (justify ? 'center' : "")};
  cursor:pointer;
  cursor: ${({ cursor }) => (cursor ? cursor : "pointer")};
  align-items:center;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  .calendar{
    width:20px;
    height:20px;
    margin:0 10px 0 15px;
    >img{
      width:100%;
      height:100%;
    }
  }
  .rightMark{
    width:24px;
    height:24px;
    display: flex;
    margin: auto;
    margin-right: 10px;
    >img{
      width:100%;
      height:100%;
    }
  }
`;

const NoEvents = styled.div`
display:flex;
width:100%;
justify-content:center;
flex-wrap: wrap;
.imageCard{
  Width:422px;
  height:350px;
  margin-top:60px;
  >img{
    width:100%;
    height:100%;
  }
}
.text{
  font-family: Rubik-Medium;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #0D4270;
  text-align:center;
  padding:25px;
  width:100%;
}
.buttonContainer{
  width:100%;
  display:flex;
  justify-content:center;
  .buttons{
    width:320px;
    height:50px;
  }
}
`;

const SliderWrapper = styled.div`
  width: 100%;

  .slick-slider {
    width: 100%;
    margin: ${({marginValue}) => marginValue ? marginValue : '20px 0 10px'};
    padding: ${({padding}) => padding ? padding : ''};
    box-shadow: none;

    .slick-slide{
      margin: 0px;
      display: flex;
      justify-content: center;
      display: ${({display}) => display ? display : 'block'};
      justify-content: ${({content}) => content ? content : ''};
      margin: ${({margin}) => margin ? margin : ''};
      @media (max-width: 600px){
        margin: 0 auto;
      }
    }
    .slick-prev {
      display:none !important;
    }

    .slick-next {
      display:none !important;
    }
      [dir="rtl"] .slick-next:before {
        content: "‹";
      }
  }
`;

export {Layout, Container, CompanyCard, Button, WolibaCard, WolibaButton, MainContainer, AddToCalendarButton, NoEvents, SliderWrapper};
